
export const myAccountPage = () => {
    const printButtons = [].slice.call(document.querySelectorAll('[data-print-button]'));

    printButtons.forEach(button => {
        button.addEventListener('click', () => {
            window.addEventListener('afterprint', resetPrintAdjustment);
            const currentPanel = button.closest('[data-orderid]');
            if (currentPanel) {
                currentPanel.classList.add('active-ticket');
            }
            setTimeout(() => {
                window.print();
            }, 0);
        })
    })
}

const resetPrintAdjustment = () => {
    const activeTicket = document.querySelector('.active-ticket');
    if (activeTicket) {
        activeTicket.classList.remove('active-ticket');
    }
}