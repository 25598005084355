import axios from 'axios';
import { StateSummary, Address } from '../interfaces/address';

export default class AddressApi {
    private static aceAddressAPIRoute = "ace-api/address";
    private static aceAddressGetStateAPIRoute = "/states";

    //private static textCache = memoize(CalendarApi.getTextFromApi, (pageId: number, culture: string) => `${pageId}${culture}`);


    /**
     * Updating the address API.
     * @param id
     * @param address1: string
     * @param address2: string
     * @param city : string
     * @param state
     * @param zipCode
     * @param country
     * @param phone
     */
    public static updateAddress(id: number, address1: string, address2: string, city: string, state: number, zipCode: string, country: number, phone: string, isPrimary: boolean): Promise<any> {
        var requestString: string = '/';


        requestString = requestString + this.aceAddressAPIRoute + "/updateAddress";

        return axios.post(requestString,
            {
                id: id < 0 ? "" : id.toString(),
                address1,
                address2,
                city,
                state: state == 0 ? null : state,
                zipCode,
                country: +(country) > 0 ? country : -1,
                phone,
                isPrimary: isPrimary
            }
        ).then(response => response.data);
    }

    /**
     * Removing the address API.
     * @param id
     */
    public static removeAddress(id: number): Promise<any> {
        var requestString: string = '/';


        requestString = requestString + this.aceAddressAPIRoute + "/removeAddress?addressId=" + id;
        return axios.get<Array<Address>>(requestString, {
            withCredentials: true,
        }).then(response => response.data);
    }

    /**
    * Updating the address API.
    * @param countryId: number
    */
    public static getStatesForCountry(countryId: number): Promise<Array<StateSummary>> {
        var requestString: string = '/';


        requestString = requestString + this.aceAddressAPIRoute + this.aceAddressGetStateAPIRoute + "?countryId=" + countryId;

        return axios.get<Array<StateSummary>>(requestString, {
            withCredentials: true,
        }).then(response => response.data);

    }

}
