const formArea = document.getElementById("QuestionForm");
const selectedSpecialActivityNumber = document.getElementById("SpecialActivityRequest_SpecialActivityNumber") as HTMLInputElement;
const selectedSpecialActivityIsLong = document.getElementById("SpecialActivityRequest_IsLongForm") as HTMLInputElement;
const selectedSpecialActivityGuestNotes = document.getElementById("SpecialActivityRequest_GuestNotes") as HTMLInputElement;
const selectedSpecialActivityShouldBook = document.getElementById("SpecialActivityRequest_ShouldBook") as HTMLInputElement;
const selectedSpecialActivityTitle = document.getElementById("SelectedSpecialActivityTitle") as HTMLInputElement;
const radioButtons = [].slice.call(document.querySelectorAll("[type=radio]")) as HTMLInputElement[];
const textInputs = [].slice.call(document.querySelectorAll("[type=text]")) as HTMLInputElement[];
const numberInputs = [].slice.call(document.querySelectorAll("[type=number]")) as HTMLInputElement[];
const additionalQuestions = [].slice.call(document.querySelectorAll("[name='SpecialActivityRequest.AdditionalQuestions']")) as HTMLInputElement[];
const responseHeading = document.querySelector('#FormHeading') as HTMLElement;

const bookingQuestions = [].slice.call(document.querySelectorAll("[name='SpecialActivityRequest.BookingStatus']")) as HTMLInputElement[];

export const specialActivitiesPage = () => {
    const selectButtons = [].slice.call(document.querySelectorAll("[data-special-activity-number]")) as HTMLElement[];
    const specialActivitySelected = selectedSpecialActivityNumber && selectedSpecialActivityNumber.value;
    if (selectButtons.length > 0) {
        selectButtons.forEach(selectButton => {
            selectButton.addEventListener("click", updateSelectedActivity);
            if (specialActivitySelected && selectButton.dataset.specialActivityNumber === selectedSpecialActivityNumber.value) {
                updateForm(selectButton);
            }
        });
    }
    if (additionalQuestions.length > 0) {
        additionalQuestions.forEach(additionalQuestion => {
            additionalQuestion.addEventListener("change", updateGuestNotes);
        });
    }
    if (bookingQuestions.length > 0) {
        bookingQuestions.forEach(bookingQuestion => {
            bookingQuestion.addEventListener("click", updateBookingInfo);
        })
    }
}

const updateSelectedActivity = (e) => {
    clearInputs(e.currentTarget);
    updateForm(e.currentTarget);
    formArea.scrollIntoView(true);
    responseHeading.focus({ preventScroll: true });
}

const updateForm = (button) => {
    if (button.dataset.longForm === 'True') {
        selectedSpecialActivityIsLong.value = "true";
    }
    else {
        selectedSpecialActivityIsLong.value = "false";
    }
    selectedSpecialActivityIsLong.dispatchEvent(new Event('change'));
    selectedSpecialActivityTitle.value = button.dataset.specialActivity;
    selectedSpecialActivityTitle.dispatchEvent(new Event('change'));
    selectedSpecialActivityNumber.value = button.dataset.specialActivityNumber;
    selectedSpecialActivityNumber.dispatchEvent(new Event('change'));
}

const updateGuestNotes = (e) => {
    if (selectedSpecialActivityGuestNotes.value) {
        var questionsAndAnswers = JSON.parse(selectedSpecialActivityGuestNotes.value);
        var questionAnswer = questionsAndAnswers.find(x => x.question === e.currentTarget.getAttribute("data_question"));
        if (questionAnswer)
            questionAnswer.answer = e.currentTarget.value
        else {
            const newQuestionAnswer: QuestionAnswer = {
                question: e.currentTarget.getAttribute("data_question"),
                answer: e.currentTarget.value
            };
            questionsAndAnswers.push(newQuestionAnswer);
        }
        selectedSpecialActivityGuestNotes.value = JSON.stringify(questionsAndAnswers);
    }
    else {
        let questionsAndAnswers = [];
        const newQuestionAnswer: QuestionAnswer = {
            question: e.currentTarget.getAttribute("data_question"),
            answer: e.currentTarget.value
        };
        questionsAndAnswers.push(newQuestionAnswer);
        selectedSpecialActivityGuestNotes.value = JSON.stringify(questionsAndAnswers);
    }
}

const updateBookingInfo = (e) => {
    selectedSpecialActivityShouldBook.value = e.currentTarget.getAttribute("data_should_book");
}

const clearInputs = (button) => {

    if (radioButtons.length > 0) {
        radioButtons.forEach(radioButton => {
            radioButton.checked = false;
        });
    }
    if (textInputs.length > 0) {
        textInputs.forEach(textInput => {
            textInput.value = '';
        })
    }
    if (numberInputs.length > 0) {
        numberInputs.forEach(numberInput => {
            numberInput.value = '';
        })
    }
    selectedSpecialActivityGuestNotes.value = '';

}


interface QuestionAnswer {
    question: string;
    answer: string;
}

interface SpecialActivityResponse {
    specialActivityNumber: string;
    numberOfAttendees: number;
    receptionStatusId: number;
    receptionStatusValue: string;
    toBookNumber: number;
    bookingStatus: number;
    bookingStatusValue: string;
    contactName: string;
    guestNotes: string;
}