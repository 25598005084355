
export class TelemarketerTimer {

    private secondsRemaining: number;
    private intervalHandle: NodeJS.Timer;
    constructor() {
        var remainingTime: string = window["telemarketerTimerSeconds"];
        if (remainingTime !== undefined) {
            this.tick = this.tick.bind(this);

            var startingMinutes = +remainingTime.split(':')[0];
            var startingSeconds = +remainingTime.split(':')[1];

            this.startCountDown();

            this.secondsRemaining = (+startingMinutes * 60) + startingSeconds;
            this.intervalHandle;
        }
    }

    tick() {
        var min = Math.floor(this.secondsRemaining / 60);
        var sec = this.secondsRemaining - (min * 60);

        if (min === 0 && sec === 0) {
            clearInterval(this.intervalHandle);
            let cartTimerWrapper = document.getElementById("telemarketer-timer");
            cartTimerWrapper.classList.add('expired');

        }

        this.secondsRemaining--
    }

    startCountDown() {
        this.intervalHandle = setInterval(this.tick, 1000);
    }

}