import * as Sticky from 'sticky-js';


const initStickyCart = () => {
    const stickyCart = new Sticky('[data-sticky-el]');
    stickyCart.update();
    return stickyCart;
}

const stickyCart = initStickyCart();
export { stickyCart };