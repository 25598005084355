
export class AceLoader {
    loaderElement: HTMLElement;

    constructor(loaderElement?: HTMLElement) {
        if (loaderElement) {
            this.loaderElement = loaderElement;
        } else {
            var defaultLoader = document.getElementsByClassName('ace-loader')[0];

            if (!defaultLoader) {
                console.log("Could not find ace-loader on this page");
            } else {
                this.loaderElement = (<HTMLElement>defaultLoader);
            }
        }

    }

    public show = () => {
        this.loaderElement.style.display = "";
    };

    public hide = () => {
        this.loaderElement.style.display = "none";
    }

}