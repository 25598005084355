
const alertCookieValue = 'dismissed';

export class Alert {
    alertElement: HTMLElement;
    dismissHandlers: Array<any>;
    alertId: string;
    closeButton: HTMLButtonElement;

    constructor(alertElement: HTMLElement, container?: HTMLElement) {
        this.alertElement = alertElement;
        this.dismissHandlers = [];
        this.alertId = this.alertElement.getAttribute('data-alert') || null;
        this.closeButton = this.alertElement.querySelector('[data-close]') as HTMLButtonElement;

        const isDismissed = this.getCookie(this.alertId);
        if (isDismissed) {
            this.alertElement.style.display = 'none';
        }
        else if(this.closeButton) {
            this.closeButton.addEventListener('click', this.dismiss.bind(this));
        }
    }

    on(event, handler) {
        switch (event) {
            case "dismiss":
                return this.dismissHandlers.push(handler);
        }
    }

    dismiss() {
        this.alertElement.style.display = 'none';
        if (this.alertId && this.alertId !== "true") {
            this.setCookie(this.alertId, alertCookieValue, 7);
        }
        if (this.dismissHandlers) {
            this.dismissHandlers.forEach(handler => {
                setTimeout(handler.bind(this), 0);
            });
        }
    }

    setCookie(name: string, value: string, days: number) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie(name: string) {
        const nameString = `${name}=`;
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.indexOf(nameString) > -1) {
                return cookie.substring(nameString.length, cookie.length);
            }
        }
        return null;
    }
}