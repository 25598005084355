const mainOverlayClass = "nav-open";

interface navItem {
    trigger: HTMLButtonElement;
    panel: HTMLElement;
}

export class Navigation {
    container: HTMLElement;
    navItems: navItem[];
    activeItem: navItem;
    main: HTMLElement;
    mobileToggle: HTMLButtonElement;
    mobilePanel: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.navItems = [];
        this.activeItem = null;
        this.init();

        document.body.addEventListener('click', (e: Event) => {
            if (this.activeItem) {
                const clickedOutsideOfNav = !this.container.contains(e.target as Element);
                if (clickedOutsideOfNav) {
                    this.closePanel(this.activeItem);
                    this.activeItem = null;
                }
            }
        });

        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.activeItem) {
                this.closePanel(this.activeItem);
                this.activeItem = null;
            }
        });
    }

    init() {
        this.main = document.querySelector('main') as HTMLElement;
        const triggers = [].slice.call(this.container.querySelectorAll('[data-toggle-submenu]')) as HTMLButtonElement[];
        triggers.forEach(trigger => {
            const index = trigger.getAttribute('aria-controls');
            const navItem = {
                trigger: trigger,
                panel: document.querySelector(`#${index}`) as HTMLElement
            }
            this.navItems.push(navItem);
        });
        this.navItems.forEach(navItem => {
            navItem.trigger.addEventListener('click', () => {
                if (navItem === this.activeItem) {
                    this.closePanel(navItem);
                    this.activeItem = null;
                } else {
                    if (this.activeItem) {
                        this.closePanel(this.activeItem);
                    }

                    this.activeItem = navItem;
                    this.openPanel(navItem);
                }
            });
        });
        this.mobileToggle = this.container.querySelector('[data-toggle-main]') as HTMLButtonElement;
        if (this.mobileToggle) {
            const id = this.mobileToggle.getAttribute('aria-controls');
            this.mobilePanel = this.container.querySelector(`#${id}`) as HTMLElement;
            this.mobileToggle.addEventListener('click', () => {
                if (this.mobilePanel.getAttribute('aria-hidden') == 'true') {
                    this.openPanel({ trigger: this.mobileToggle, panel: this.mobilePanel });
                }
                else {
                    this.closePanel({ trigger: this.mobileToggle, panel: this.mobilePanel });
                    if (this.activeItem) {
                        this.closePanel(this.activeItem);
                    }
                }
            })
        }
    }

    openPanel(selectedNavItem: navItem) {
        selectedNavItem.panel.setAttribute('aria-hidden', 'false');
        selectedNavItem.trigger.setAttribute('aria-expanded', 'true');
        this.main.classList.add(mainOverlayClass);
    }

    closePanel(deselectedNavItem: navItem) {
        deselectedNavItem.panel.setAttribute('aria-hidden', 'true');
        deselectedNavItem.trigger.setAttribute('aria-expanded', 'false');
        this.main.classList.remove(mainOverlayClass);
    }
}