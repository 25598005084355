import { Navigation } from '../classes/Navigation';

export const initNavSubmenus = () => {
    const navContainerEls = [].slice.call(document.querySelectorAll('[data-main-nav]')) as HTMLElement[];
    let navigations = [];
    navContainerEls.forEach(nav => {
        navigations.push(new Navigation(nav));
    });

    return navigations;
}