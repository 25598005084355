export const specialProductionPage = () => {
    const submitButtons = document.querySelectorAll("[type=submit]");
    if (submitButtons.length > 0) {
        submitButtons.forEach(submitButton => {
            submitButton.addEventListener("click", updateSelectedPerformance)
        });
    }
    const venueDropDown = document.getElementById("venues");
    if (venueDropDown) {
        venueDropDown.addEventListener("change", updateShownPerformances);
    }
}

const updateSelectedPerformance = (e) => {
    let selectedPerformance = document.getElementById("SelectedPerformance") as HTMLInputElement;
    if (selectedPerformance) {
        const clickedProductionId = e.currentTarget.id.split('-')[1]
        let selectedTime = document.getElementById("times-" + clickedProductionId) as HTMLInputElement;
        if (selectedTime) {
            selectedPerformance.value = selectedTime.value;
        }
        else {
            selectedTime = document.getElementById("time-" + clickedProductionId) as HTMLInputElement;
            selectedPerformance.value = selectedTime.value;
        }
    }
}

const updateShownPerformances = (e) => {
    const performanceBlocks = [].slice.call(document.querySelectorAll("[data-venue]")) as HTMLElement[];
    if (performanceBlocks) {
        const selectedVenue = e.currentTarget.value;
        performanceBlocks.forEach(block => {
            if (selectedVenue === "") {
                block.hidden = false;
            }
            else {
                const venue = block.getAttribute("data-venue");
                block.hidden = venue !== selectedVenue;
            }
        })
    }
}