import { StateSummary } from '../interfaces/address';
import { AceLoader } from './ace-loader';
import AddressApi from './address-api';

export class AddressModal {
    modalElement: HTMLElement;
    loader: AceLoader;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        this.modalElement = modalElement;
        this.loader = new AceLoader();

        let saveAddressButtons = modalElement.querySelectorAll('[data-save-btn]');
        if (saveAddressButtons != null) {
            for (var i = 0; i < saveAddressButtons.length; i++) {
                saveAddressButtons[i].addEventListener('click', this.saveAddressForm.bind(this));
            }
        }

        var removeModal = this.modalElement.classList.contains('remove');
        if (!removeModal) {
            let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country'));
            country.addEventListener('change', this.getStatesByCountry.bind(this));
        }

        let removeAddressButtons = modalElement.querySelectorAll('[data-remove-btn]');
        if (removeAddressButtons != null) {
            for (var i = 0; i < removeAddressButtons.length; i++) {
                removeAddressButtons[i].addEventListener('click', this.removeAddressForm.bind(this));
            }
        }
    }

    saveAddressForm = (e) => {
        e.preventDefault();
        if (this.validateAddress()) {
            let id = (<HTMLInputElement>this.modalElement.querySelector('[name=id')).value;
            let isPrimary = (<HTMLInputElement>this.modalElement.querySelector('[name=isPrimary'))?.value;
            let address1 = (<HTMLInputElement>this.modalElement.querySelector('[name=address1')).value;
            let address2 = (<HTMLInputElement>this.modalElement.querySelector('[name=address2')).value;
            let city = (<HTMLInputElement>this.modalElement.querySelector('[name=city')).value;
            let state = (<HTMLSelectElement>this.modalElement.querySelector('[name=state')).value;
            let zipcode = (<HTMLInputElement>this.modalElement.querySelector('[name=zipCode')).value;
            let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country')).value;

            var phoneLabel = (<HTMLSelectElement>this.modalElement.querySelector('[name=phone'));

            let phone = "";
            if (phoneLabel)
                phone = phoneLabel.value;

            this.loader.show();
            return AddressApi.updateAddress(+id, address1, address2,
                city, +state, zipcode, +country, phone, isPrimary == "true").then(() => {
                    window.location.reload();
                })

        }
    };

    removeAddressForm = (e) => {
        e.preventDefault();
        let id = (<HTMLInputElement>this.modalElement.querySelector('[name=id')).value;

        this.loader.show();
        return AddressApi.removeAddress(+id).then(() => {
            window.location.reload();
        })
    };

    validateAddress = () => {
        let address1 = (<HTMLInputElement>this.modalElement.querySelector('[name=address1'));
        let city = (<HTMLInputElement>this.modalElement.querySelector('[name=city'));
        let state = (<HTMLSelectElement>this.modalElement.querySelector('[name=state'));
        let zipcode = (<HTMLInputElement>this.modalElement.querySelector('[name=zipCode'));
        let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country'));

        //(<HTMLSelectElement>this.modalElement.querySelector('#address1-valMsg')).style.display = address1.value.length ? 'none' : '';
        //(<HTMLSelectElement>this.modalElement.querySelector('#city-valMsg')).style.display = city.value.length ? 'none' : '';
        //(<HTMLSelectElement>this.modalElement.querySelector('#state-valMsg')).style.display = state.value.length || state.disabled ? 'none' : '';
        //(<HTMLSelectElement>this.modalElement.querySelector('#zipCode-valMsg')).style.display = zipcode.value.length ? 'none' : '';
        //(<HTMLSelectElement>this.modalElement.querySelector('#country-valMsg')).style.display = country.value.length ? 'none' : '';

        return address1.value.length && city.value.length && (state.value.length || state.disabled) && zipcode.value.length && country.value.length;
    }

    getStatesByCountry = (e: Event) => {

        let country = (<HTMLSelectElement>e.currentTarget).value;
        AddressApi.getStatesForCountry(+country).then(results => {
            this.updateStatesDropDown(results)
        })
    }

    updateStatesDropDown = (states: Array<StateSummary>) => {
        let stateDropDown = (<HTMLSelectElement>this.modalElement.querySelector('[name=state'));

        stateDropDown.innerHTML = '';
        if (states.length) {
            states.forEach(state => {
                var option = document.createElement('option');
                option.value = state.id.toString();
                option.innerHTML = state.description;
                stateDropDown.appendChild(option);
            });
            stateDropDown.disabled = false;
        }
        else {
            stateDropDown.disabled = true;
        }
    }
}